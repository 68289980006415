@each $name, $color in $theme-colors {
  .chip.#{'chip--' + $name} {
    --chip-background-color: var(--bs-#{$name}-bg-subtle);
    --chip-border-color: var(--bs-#{$name}-border-subtle);
    --chip-color: var(--bs-#{$name});
  }
}

.chip.chip--light {
  --chip-color: var(--bs-secondary-color);
}
.chip.chip--dark {
  --chip-color: var(--bs-emphasis-color);
}

.chip--rsp {
  --chip-border-color: var(--bs-primary-border-subtle);
  background-color: transparent;
}
.chip--cv {
  border-color: transparent;
  background-color: transparent;
}

.chip--project {
  background-color: var(--project-color, var(--bs-primary));
  color: var(--project-text-color, #fff);
  width: 100%;
  text-align: center;
}

// Used to create space where chip would be if its not there
.chip--spacer {
  border-color: transparent;
  background-color: transparent;
}
