.chip {
  --chip-color: var(--bs-primary);
  --chip-background-color: var(--bs-primary-bg-subtle);
  --chip-border-color: transparent;

  max-width: clamp(auto, 200px, 100%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: var(--chip-background-color);
  color: var(--chip-color);
  border-radius: 40px;
  font-weight: 600;
  font-size: 0.9em;
  padding: 0 0.7em;
  display: inline-block;
  border: 2px solid var(--chip-border-color);
  height: 2em;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  .mat-icon {
    width: auto;
    height: auto;
    font-size: 1.2em;
  }

  > span {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.chip.chip--round {
  aspect-ratio: 1/1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  text-align: center;
}
