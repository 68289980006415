.mdc-tab__text-label {
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 400;
}

.mat-mdc-tab-nav-bar .mat-mdc-tab-links {
  --mat-tab-header-inactive-hover-label-text-color: var(--bs-primary);

  .mdc-tab {
    min-width: 90px;
    padding-right: var(--mdc-tab-spacing-x);
    padding-left: var(--mdc-tab-spacing-x);
  }

  .mat-mdc-tab-link:hover {
    color: var(--bs-primary);

    .mdc-tab__ripple::before {
      opacity: 0;
    }
  }
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 55px;
}

//.mat-mdc-tab-link.mdc-tab--active .mdc-tab__ripple::before,
//.mat-mdc-tab-link.mdc-tab--active .mat-ripple-element {
//  scale: 0.5;
//}

@include theme('light') {
  //.mat-mdc-tab-nav-bar {
  //  --mat-tab-header-active-ripple-color: var(--bs-primary-bg-subtle);
  //}
  .mat-mdc-tab-link .mat-ripple-element {
    opacity: 0.05;
  }
}

.bar-sticky {
  position: sticky !important;
  top: 0;
  z-index: 3;
  background: var(--bs-body-bg);
}

html .mat-mdc-tab-link .mdc-tab-indicator__content--underline {
  --mdc-tab-indicator-active-indicator-height: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
