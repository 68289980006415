// Bootstrap
$white: #fff;
$gray-100: #f3f3f3;
$gray-200: #dfe2e2;
$gray-300: #b6bcbc;
$gray-400: #a5aeae;
$gray-500: #869292;
$gray-600: #637272;
$gray-700: #4f6161;
$gray-800: #354a4a;
$gray-900: #183030;

$black: #000;

$blue: #0878af;
$indigo: #6610f2;
$purple: #512178;
$pink: #d63384;
$red: #c41342;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #00888b;
$cyan: #0dcaf0;

$primary: $blue;
$secondary: $red;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: white;
$dark: $gray-900;

// add custom colors in this map and also check _color-maps.scss for further implementation
$accent: $teal;
$custom-theme-colors: (
  'accent': $accent,
);

$primary-text-emphasis: shade-color($primary, 5%);
$secondary-text-emphasis: shade-color($secondary, 60%);
$success-text-emphasis: shade-color($success, 60%);
$info-text-emphasis: shade-color($info, 60%);
$warning-text-emphasis: shade-color($warning, 60%);
$danger-text-emphasis: shade-color($danger, 60%);
$light-text-emphasis: $gray-700;
$dark-text-emphasis: $gray-700;

$primary-bg-subtle: tint-color($primary, 90%);
$secondary-bg-subtle: tint-color($secondary, 80%);
$success-bg-subtle: tint-color($success, 80%);
$info-bg-subtle: tint-color($info, 80%);
$warning-bg-subtle: tint-color($warning, 80%);
$danger-bg-subtle: tint-color($danger, 80%);
$light-bg-subtle: mix($gray-100, $white);
$dark-bg-subtle: $gray-400;

$primary-bg-subtle-dark: shade-color($primary, 75%) !default;

$primary-border-subtle: tint-color($primary, 80%);
$secondary-border-subtle: tint-color($secondary, 60%);
$success-border-subtle: tint-color($success, 60%);
$info-border-subtle: tint-color($info, 60%);
$warning-border-subtle: tint-color($warning, 60%);
$danger-border-subtle: tint-color($danger, 60%);
$light-border-subtle: $gray-200;
$dark-border-subtle: $gray-800;

$body-secondary-color: $gray-400;
//$body-bg-dark: #283a48;

$gradient: radial-gradient(104.99% 63.95% at 100% 100%, rgba($white, 0.2) 0%, transparent 100%);
$color-mode-type: media-query;

// Other
//? Put own extra variables here
$bli-status-colors: (
  //new: var(--bs-body-bg),
  new: white,
  doing: #f2a93b,
  approved: #4d8ba0,
  verify: #fce648,
  ready: #fce648,
  done: #8ac249,
  danger: rgba(196, 19, 66, 1),
  priority: rgba(196, 19, 66, 1),
  info: #0878af
);

$discipline-colors: (
  //new: var(--bs-body-bg),
  frontend: #5cb85c,
  backend: #5bc0de,
  design: purple,
  misc: #0878af
);
