.button.button-outline {
  background: transparent;
  color: var(--#{$prefix}btn-bg);
  border-color: var(--#{$prefix}btn-bg);

  fstr-icon .icon {
    fill: currentColor;
  }

  @media (hover: hover) {
    &:hover {
      background: var(--#{$prefix}btn-bg-alpha);
      color: var(--#{$prefix}btn-bg-darker);
      border-color: var(--#{$prefix}btn-bg-darker);
    }
  }
}
