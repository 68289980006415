@mixin button-icon() {
  @include size(rem($button-size));
  border-radius: rem(200px);
  display: inline-flex;
  justify-content: center;
  padding: 0;

  span + fstr-icon {
    margin-inline-end: 0;
  }
}

.button-icon {
  @include button-icon;
}
