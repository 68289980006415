.check-list__item {
  display: flex;
  justify-content: space-between;
  transition: background-color 0.2s;

  &:hover {
    background: var(--bs-primary-bg-subtle);
  }

  label {
    width: 100%;
    display: inline-block;
    cursor: pointer;
  }
}

.check-list__field {
  .mat-mdc-form-field-icon-suffix {
    align-self: flex-end;
    margin-bottom: 0.6em;
  }
}

.input-mask {
  pointer-events: none;
}

.text-transparent {
  color: none;
}

.mat-mdc-checkbox-checked {
  .highlight__nice-to-have {
    animation: backgroundShiftAgain 1s 0.5s 1;
  }
}
