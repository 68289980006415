.mat-mdc-autocomplete-panel {
  --mat-optgroup-label-text-color: var(--bs-body-color);

  &.command-palette-results-panel {
    max-height: calc(100vh - 500px);
    box-shadow: none;

    .mat-mdc-option .mat-icon {
      margin-right: 0;
      --icon-size: 1.6em;
      line-height: var(--icon-size);
      width: auto;
    }
  }

  .mdc-list-item__primary-text {
    width: 100%;
  }
  .mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
    padding-left: 16px;
  }
  .mat-mdc-optgroup-label {
    min-height: 35px;
    align-items: flex-end;
    padding-bottom: 4px;

    .mdc-list-item__primary-text {
      font-size: 13px;
      color: var(--bs-primary-400);
      font-weight: 500;
    }
  }

  mat-option.empty-state {
    text-align: center;
    color: var(--bs-primary);
  }
}
