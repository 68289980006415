.mat-mdc-tooltip {
  // Monochrome
  --mdc-plain-tooltip-supporting-text-color: var(--bs-primary-200);
  --mdc-plain-tooltip-container-color: var(--bs-primary-800);
}

.tooltip-primary {
  --mdc-plain-tooltip-supporting-text-color: white;
  --mdc-plain-tooltip-container-color: var(--bs-primary);

  .mdc-tooltip__surface {
    font-size: 14px;
    padding: 0.5em 0.6em;
  }

  // chevron
  .mat-mdc-tooltip-panel-right & .mdc-tooltip__surface {
    overflow: visible;

    &::after {
      content: '';
      --chevron-size: 5px;
      width: 0;
      height: 0;
      border-top: var(--chevron-size) solid transparent;
      border-right: calc(var(--chevron-size) * 1.4) solid var(--mdc-plain-tooltip-container-color);
      border-bottom: var(--chevron-size) solid transparent;
      margin-top: 11px;
      order: -1;
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-100%);
    }
  }
}
