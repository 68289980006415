.field-compact-side {
  --mat-form-field-container-vertical-padding: 5px;
  --mat-form-field-container-height: 20px;

  &-container {
    display: flex;
    gap: spacer(2);
    align-items: center;
  }

  &__label {
    width: 100%;
    font-weight: 500;
    line-height: 1.1;
  }

  .mdc-text-field {
    padding-left: calc(var(--mat-form-field-container-vertical-padding) * 2);
  }
}
