@use '@angular/material' as mat;
@use 'sass:map';

@include mat.core();

// Helpers
@import './mixins/all';
@import './functions/all';

$mat-other-colors-map: (
  secondary: generate-color-map($primary),
  neutral: generate-color-map(#e6f2f7),
  neutral-variant: generate-color-map(#e6f2f7),
  error: generate-color-map($danger),
);

$custom-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: map.merge(generate-color-map($primary), $mat-other-colors-map),
      tertiary: map.merge(generate-color-map($primary), $mat-other-colors-map),
      use-system-variables: true,
    ),
    typography: (
      plain-family: $font-family-serif,
      brand-family: $font-family-serif,
    ),
    density: (
      scale: -1,
    ),
  )
);

$mat-other-dark-colors-map: (
  secondary: generate-color-map($primary),
  neutral: generate-color-map(#e6f2f7),
  neutral-variant: generate-color-map(#093b55),
  error: generate-color-map($danger),
);

$custom-dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      use-system-variables: true,
      primary: map.merge(generate-color-map($primary), $mat-other-dark-colors-map),
      tertiary: map.merge(generate-color-map($primary), $mat-other-dark-colors-map),
    ),
    typography: (
      plain-family: $font-family-serif,
      brand-family: $font-family-serif,
    ),
    density: (
      scale: -1,
    ),
  )
);

@include theme('light') {
  @include mat.all-component-themes($custom-theme);
  @include mat.system-level-colors($custom-theme);
}

@include theme('dark') {
  @include mat.all-component-themes($custom-dark-theme);
  @include mat.system-level-colors($custom-dark-theme);
}

// For both themes same
@include theme(null) {
  --mat-option-selected-state-layer-color: #{rgba($primary, 0.15)};
  --mat-standard-button-toggle-selected-state-background-color: #{rgba($primary, 0.15)};
  --mat-autocomplete-background-color: var(--bs-body-bg);
  --mat-standard-button-toggle-divider-color: var(--bs-primary-bg-subtle);
  --mdc-dialog-container-color: var(--bs-body-bg);
  --mat-select-panel-background-color: var(--bs-body-bg);
}

@import './config/config';

//.app-container {
//}
@import './components/all';
