// loading state for list items

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.shimmer {
  &.is--updating {
    filter: grayscale(1);
    overflow: hidden;

    &::after {
      --shimmer-color: white;
      @include abs();
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        color-mix(in srgb, var(--shimmer-color) 0%, transparent) 0,
        color-mix(in srgb, var(--shimmer-color) 20%, transparent) 20%,
        color-mix(in srgb, var(--shimmer-color) 50%, transparent) 60%,
        color-mix(in srgb, var(--shimmer-color) 0%, transparent)
      );
      animation: shimmer 2s infinite linear;
      content: '';
    }
  }
}
