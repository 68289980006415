// Boostrap
:root {
  //--shadow-default-color: #{rgba($gray-900, 0.15)};
  --shadow-default-color: #{$gray-900};
}
$box-shadow: 0 0.1rem 1rem color-mix(in srgb, #{var(--shadow-default-color)} 15%, transparent);
$box-shadow-sm: 0 0.25rem 1rem color-mix(in srgb, #{var(--shadow-default-color)} 15%, transparent);
$box-shadow-lg: 0.5rem 0.5rem 3rem color-mix(in srgb, rgb(222, 228, 234) 50%, transparent);
// $box-shadow-xl: 0.5rem 0.5rem 3rem color-mix(in srgb, var(--shadow-default-color) 15%, transparent);
$box-shadow-inset: inset 0 1px 2px color-mix(in srgb, #{var(--shadow-default-color)} 70%, transparent);
