.mat-mdc-radio-button {
  --mdc-radio-unselected-icon-color: var(--bs-light-border-subtle);
  --mdc-radio-unselected-hover-icon-color: var(--bs-tertiary-color);
  --mdc-radio-state-layer-size: 32px;
  --mat-radio-label-text-color: var(--bs-body-color);

  .mdc-form-field {
    --mat-radio-label-text-color: var(--bs-body-color);
    --mdc-radio-unselected-icon-color: var(--bs-body-color);
    align-items: start;
    color: var(--bs-body-color);
  }

  .mdc-radio__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true']) ~ .mdc-radio__background {
    background: var(--bs-tertiary-bg);
  }

  .mdc-radio__background {
    border-radius: 5px;
  }

  label {
    margin-top: 0.35em;
  }
}
