.container-size {
  @each $bp, $width in $container-sizes {
    @media screen and (min-width: #{map-get($container-max-widths, sm)}) {
      &-#{$bp} {
        max-width: $width;
        --container-max-width: #{$width};
      }
    }
  }
}

.container,
.container-fluid {
  --container-max-width: 100%;

  @include media-breakpoint-down(md) {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
// Copied from bootstrap, but made it an variable instead of value only
// Responsive containers that are 100% wide until a breakpoint
@each $breakpoint, $container-max-width in $container-max-widths {
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    %responsive-container-#{$breakpoint} {
      --container-max-width: #{$container-max-width};
    }
  }
}
