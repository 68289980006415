.tab-button {
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  color: var(--bs-primary);
  text-decoration: none;
  padding: 0.6em;
  transition:
    color 0.2s,
    background-color 0.2s;
  border-radius: 7px;

  &:hover,
  &:focus,
  &.is--active {
    background-color: var(--bs-primary-100);
  }

  .mat-icon {
    --icon-size: 1.4em;
  }

  &__label {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }

  &.is--active {
    background: var(--bs-primary);
    color: white;
  }

  &--accent {
    background-color: var(--bs-primary-100);
    //border: 2px solid var(--bs-primary-bg-subtle);

    @media (hover: hover) {
      &:hover {
        background-color: var(--bs-primary-200);
        //border-color: var(--bs-primary-200);
      }
    }

    &:hover,
    &:focus,
    &.is--active {
      background-color: var(--bs-primary-100);
    }
  }

  &--small {
    padding: 0.45em 0;

    .mat-icon {
      --icon-size: 1.2em;
    }

    &:not(.is--active):not(:hover):not(:focus) {
      color: var(--bs-primary-400);
    }
  }
}
