@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    box-shadow: none;
    padding: 12px;
  }

  &::-webkit-scrollbar-track {
    // background-color: var(--bs-primary-bg-subtle);
    border-radius: 10px;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--bs-primary);
    box-shadow: none;
  }
}
