.slide-container {
  display: contents;

  @media screen and (min-width: 2420px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__inner {
    margin: 0;
    transition:
      transform 0.2s,
      left 0.2s;

    @media screen and (max-width: 2420px) {
      transform: translateX(-50%);
      left: 50%;
      position: relative;
      will-change: left, transform;
    }
  }

  &.is--open {
    .slide-container__inner {
      left: 0% !important;
      transform: translateX(0%) !important;

      @media screen and (min-width: 2420px) {
        transform: translateX(calc(var(--backlog-sidebar-max-width) / -2)) !important;
      }
    }

    .slide-container__fabs {
      right: var(--backlog-sidebar-max-width) !important;
    }
  }
}
