.reset-link {
  text-decoration: none;
  color: currentColor;

  &:focus-visible {
    outline: 2px solid var(--bs-warning);
    outline-offset: 3px;
  }
}

.hover-underline {
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
}

.white-space-pre {
  white-space: pre !important;
}

// for 3 items
@for $i from 1 through 4 {
  .max-lines-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.word-break-all {
  word-break: break-all !important;
}

.fw-semi {
  font-weight: 500 !important;
}
