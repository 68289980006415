@keyframes backgroundShift {
  from {
    background-position: -200%;
  }
  to {
    background-position: 0%;
  }
}
@keyframes backgroundShiftAgain {
  from {
    background-position: -200%;
  }
  to {
    background-position: 0%;
  }
}

.highlight {
  color: var(--discipline-color);
  --discipline-color: var(--discipline-misc);
  font-weight: 500;

  &__frontend {
    --discipline-color: var(--discipline-frontend);
  }
  &__backend {
    --discipline-color: var(--discipline-backend);
  }
  &__design {
    --discipline-color: var(--discipline-design);
  }
  &__nice-to-have {
    --discipline-color: orange;
    background: linear-gradient(256deg, #fd7e14, yellow, #fd7e14, #e44218, #ee00ff, #fd7e14);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200%;
    background-position: -200%;
    animation: backgroundShift 1s 0.5s 1;
    animation-fill-mode: forwards;
  }

  &.animate {
    animation: none;
  }
}
