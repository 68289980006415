body {
  --mat-expansion-container-background-color: var(--bs-primary-bg-subtle);
  //--mat-expansion-container-background-color: var(--bs-body-bg);
  --mat-expansion-container-text-color: var(--bs-body-color);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: color-mix(in srgb, var(--bs-body-color), transparent 50%);
  --mat-expansion-header-text-color: var(--bs-body-color);
  --mat-expansion-header-description-color: var(--bs-body-color);
  --mat-expansion-header-indicator-color: var(--bs-body-color);

  --mat-expansion-container-shape: var(--bs-border-radius);

  --mat-expansion-spacing-x: 24px; // own variable
}

.mat-accordion {
  .mat-expansion-panel {
    border: 1px solid var(--bs-primary-border-subtle);

    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }

    &:not(:first-child) {
      transform: translate(0px, -1px);
    }
  }
}

.mat-expansion-panel {
  .mat-expansion-panel-header {
    padding: 0 var(--mat-expansion-spacing-x);
  }

  .mat-expansion-panel-body {
    padding: 0 var(--mat-expansion-spacing-x) calc(var(--mat-expansion-spacing-x) / 2);
  }
}
