.theme-test,
[data-bs-theme='test'] {
  $primary: #643863;
  $secondary: #d3d800;

  --bs-primary: #{$primary};
  --bs-primary-rgb: #{to-rgb($primary)};
  --bs-primary-text-emphasis: #{shade-color($primary, 60%)};
  --bs-primary-bg-subtle: #{tint-color($primary, 80%)};
  --bs-primary-border-subtle: #{tint-color($primary, 60%)};

  --bs-secondary: #{$secondary};
  --bs-secondary-rgb: #{to-rgb($secondary)};
  --bs-secondary-text-emphasis: #{shade-color($secondary, 60%)};
  --bs-secondary-bg-subtle: #{tint-color($secondary, 80%)};
  --bs-secondary-border-subtle: #{tint-color($secondary, 60%)};
}
