.mat-mdc-select-trigger {
  .status {
    --status-size: 1em;
  }
}

.mat-mdc-select-value,
.mat-mdc-select-arrow {
  color: var(--mdc-filled-text-field-label-text-color);
}

.cdk-overlay-pane {
  // min-width: 320px;
}

.mat-mdc-select-panel {
  min-width: 200px;
}

.mat-mdc-option.mdc-list-item {
  padding: 9px 16px;
}
