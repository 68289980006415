@keyframes loader {
  0% {
    transform: translateX(0%) scaleX(0);
  }
  50% {
    transform: translateX(33%) scaleX(33%);
  }
  100% {
    transform: translateX(100%) scaleX(0%);
  }
}

.loading-bar {
  --loading-bar-width: 3px;
  height: var(--loading-bar-width);
  background: color-mix(in srgb, var(--bs-primary-bg-subtle) 30%, transparent);
  width: 100%;
  display: flex;
  @include abs(0, 0, -1px, 0);

  &::after {
    content: '';
    display: block;
    background: white;
    width: 100%;
    animation: loader 3s infinite ease;
    transform-origin: left;
  }
}

.button .loading-bar {
  top: auto;
  margin: 0;
}

.loading-circle {
  --loading-bar-width: 3px;
  @include abs(-1px);
  aspect-ratio: 1;
  border-radius: 50%;
  border: var(--loading-bar-width) solid color-mix(in srgb, var(--bs-primary-bg-subtle) 30%, transparent);

  &::before {
    content: '';
    @include abs(calc(var(--loading-bar-width) * -1));
    aspect-ratio: 1;
    border-radius: 50%;
    border: var(--loading-bar-width) solid white;
    animation:
      l20-1 0.8s infinite linear alternate,
      l20-2 1.6s infinite linear;
  }
}
@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }
  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}
@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

.button .loading-circle {
  margin: 0;
  //width: 100%;
}
