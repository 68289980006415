.user-thumb {
  img {
    aspect-ratio: 1/1;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid var(--player-color, transparent);
    //width: 100%;
    max-width: 100px;
  }
}
