.story-point-progress-bar {
  display: flex;
  align-items: center;
  gap: 10px;

  &__bar {
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    width: 100%;
    background-color: var(--bs-primary-100);
    height: 1em;

    &__segment {
      background-color: var(--status-color);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      color: white;
      font-weight: bold;
      @include get-font-size(body-xs);

      &:hover {
        background-color: color-mix(in srgb, var(--status-color), black 10%);
      }
    }
  }
}
