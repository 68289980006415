@mixin theme($name) {
  @if ($name) {
    [data-bs-theme='#{$name}'],
    .theme-#{$name} {
      @content;
    }
  } @else {
    [data-bs-theme] {
      @content;
    }
  }
}
