fstr-icon {
  position: relative;
  display: inline-flex;
  min-width: 1em;
  min-height: 1em;

  .icon {
    @include abs(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
}

mat-icon.mat-icon {
  --icon-size: 1.2em;
  width: var(--icon-size);
  height: var(--icon-size);
  font-size: var(--icon-size);
  line-height: var(--icon-size);
}
