.chip--xs {
  font-size: 0.7em;
  padding: 0 0.4em;
  height: 1.7em;
  //line-height: 1.3em;
}

.chip--sm {
  font-size: 0.8em;
  padding: 0 0.5em;
  height: 1.8em;
  //line-height: 1.4em;
}
