body {
  --mat-legacy-button-toggle-text-color: var(--bs-body-color);
  --mat-legacy-button-toggle-state-layer-color: var(--bs-body-color);

  --mat-legacy-button-toggle-selected-state-text-color: var(--bs-body-color);
  --mat-legacy-button-toggle-selected-state-background-color: var(--bs-body-bg);

  --mat-legacy-button-toggle-disabled-state-text-color: var(--bs-body-color);
  --mat-legacy-button-toggle-disabled-state-background-color: var(--bs-body-bg);
  --mat-legacy-button-toggle-disabled-selected-state-background-color: var(--bs-body-bg);

  --mat-standard-button-toggle-text-color: var(--bs-body-color);
  --mat-standard-button-toggle-background-color: var(--bs-body-bg);
  --mat-standard-button-toggle-state-layer-color: black;

  --mat-standard-button-toggle-selected-state-background-color: var(--bs-primary-100);
  --mat-standard-button-toggle-selected-state-text-color: var(--bs-body-color);

  --mat-standard-button-toggle-disabled-state-text-color: var(--bs-body-color);
  --mat-standard-button-toggle-disabled-state-background-color: var(--bs-body-bg);
  --mat-standard-button-toggle-disabled-selected-state-text-color: var(--bs-body-color);
  --mat-standard-button-toggle-disabled-selected-state-background-color: var(--bs-body-color);

  --mat-standard-button-toggle-divider-color: var(--bs-light-border-subtle);
}

//var(--bs-body-bg)
//var(--bs-body-color)
