quill-editor {
  --ql-toolbar-color: white;
  --ql-toolbar-bg-color: var(--bs-primary);
  --ql-editor-bg-color: var(--mdc-filled-text-field-container-color);

  width: 100%;
}

.ql-toolbar.ql-snow {
  background-color: var(--ql-toolbar-bg-color);
  color: white;
  font-family: inherit;
  border: none;
  border-radius: var(--mdc-filled-text-field-container-shape);

  button {
    color: var(--ql-toolbar-color);
    margin-inline-end: 3px;

    &:hover {
      opacity: 0.8;
    }

    &.ql-active,
    &.ql-selected {
      // --ql-toolbar-color: green;
      border-radius: 2px;
      background-color: color-mix(in srgb, var(--ql-toolbar-bg-color) 80%, #000 20%);
    }
  }

  .ql-stroke {
    stroke: var(--ql-toolbar-color) !important;
  }

  .ql-fill {
    fill: var(--ql-toolbar-color) !important;
  }
}

.ql-container.ql-snow {
  background-color: var(--ql-editor-bg-color);
  border: none;
  border-radius: 0 0 var(--mdc-filled-text-field-container-shape) var(--mdc-filled-text-field-container-shape);
  position: relative;
  //max-height: 600px;
  //overflow: auto;
  min-height: 80px;
  overflow: visible;

  &::before {
    content: '';
    @include abs(0);
    opacity: 0;
    pointer-events: none;
    background-color: var(--mat-form-field-state-layer-color);
  }

  &:hover::before {
    opacity: var(--mat-form-field-hover-state-layer-opacity);
  }
}

.ql-container {
  @extend .markdown-content;

  p {
    margin-bottom: 0.5ex;
  }

  .ql-editor {
    font-size: 0.9rem;

    &.ql-blank::before {
      color: var(--bs-secondary-color);
    }

    blockquote {
      border-left: none;
    }
    img {
      margin-bottom: 1.2em;
    }
    .ql-video {
      aspect-ratio: 16 / 9;
      margin-bottom: 1.2em;
    }

    ol,
    ul {
      padding-inline-start: 1.5rem;
    }

    li {
      list-style-type: none;
      padding-left: calc(var(--list-indent) * 1.75em) !important;

      @for $i from 1 through 5 {
        &.ql-indent-#{$i}:not(.ql-direction-rtl) {
          --list-indent: #{$i};
        }
      }
    }

    *:last-child {
      margin-bottom: 0;
    }
  }
}

.ql-snow .ql-tooltip {
  z-index: 2;
  left: 0 !important;
}
