body {
  @include tint-css-variables('primary');
  --bs-primary-100: #c4e5f3;

  @each $name, $color in $discipline-colors {
    --discipline-#{$name}: #{$color};
  }

  @each $name, $color in $bli-status-colors {
    --bli-status-#{$name}: #{$color};
  }
}
