.mat-mdc-form-field {
  width: 100%;

  .mdc-text-field--filled {
    border-radius: var(--mdc-filled-text-field-container-shape);
  }
}

//.mat-mdc-form-field {
//  --mdc-filled-text-field-input-text-color: var(--bs-body-color);
//  --mdc-filled-text-field-label-text-color: var(--bs-body-color);
//  --mdc-filled-text-field-input-text-placeholder-color: var(--bs-secondary-color);
//}

.mdc-line-ripple {
  display: none;
}

.mat-mdc-select-placeholder {
  opacity: 0.4;
  //font-style: italic;
}

.cdk-textarea-autosize.mat-mdc-form-field-textarea-control {
  max-height: none !important;
  vertical-align: bottom;
}
