.current-sprint-marker {
  display: inline-block;
  width: 1em;
  min-width: 1em;
  height: 1em;
  border-radius: 12.417px;
  border: 1px solid #c05b3a;
  background: #c05b3a;
  box-shadow:
    0px 0px 3px 0px rgba(192, 91, 58, 0.5),
    0px 0px 0px 2px var(--bs-primary-bg-subtle) inset;
  margin-left: 2px;
}
