.status {
  --status-size: 1.2em;
  min-width: var(--status-size);
  width: var(--status-size);
  height: var(--status-size);
  background-color: var(--status-color, var(--bs-gray-300));
  border-radius: 100%;
  border: 1px solid transparent;
  display: inline-block;
}

@each $name, $color in $bli-status-colors {
  .status--#{$name} {
    --status-color: #{$color};

    @if $name == new {
      border-color: var(--bs-primary-border-subtle);
    }
  }
}
